






































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CompetenceCorridor from '@/components/common/CompetenceCorridor.vue'
import { State } from 'vuex-class'
import { CompetenceDetail } from '@/store/kode/types'
import Competence from '@/components/hszg/Competence.vue'
import ColorLegend from '@/components/common/ColorLegend.vue'
import { LegendType } from '@/store/types'

@Component({
  components: { ColorLegend, Competence, CompetenceCorridor }
})
// Displays the target competence profile results - used in the talent profile and the job finder page.
// @group KODE
export default class TargetCompetenceProfileResult extends Vue {
  @State('currentScreenWidth') screenWidth: number
  @State('showDesktopVersion') showDesktopVersion: boolean

  // unique identifier
  @Prop({ required: true })
  id: string

  // data from the backend (competence corridors etc)
  @Prop({ required: true })
  competenceDetails: CompetenceDetail[]

  // if the rating in form of icons (smiley) should be shown
  @Prop({ default: true })
  showRating: boolean

  get legend (): LegendType[] {
    return [
      { text: this.$i18n.t('job-finder.competence-corridor').toString(), color: this.$colors.hszgGreen },
      { text: this.$i18n.t('job-finder.unfavorable-results').toString(), color: this.$colors.kode23to34 },
      { text: this.$i18n.t('job-finder.favorable-results').toString(), color: this.$colors.kode35to40 },
      { text: this.$i18n.t('job-finder.average-results').toString(), color: this.$colors.kode41to48 }
    ]
  }

  getIcon (item: CompetenceDetail): string {
    const midpoint = (item.kodeAverageMin100 + item.kodeAverageMax100) / 2
    if (midpoint > item.corridorMax100) return 'feedback'
    else if (midpoint >= item.corridorMin100) return 'mood'
    else return 'build'
  }
}
