


























































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import DetailPage from '@/components/search-results/DetailPage.vue'
import SelectedCompetences from '@/components/kode/SelectedCompetences.vue'
import { Action } from 'vuex-class'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import TargetCompetenceProfileResult from '@/components/kode/TargetCompetenceProfileResult.vue'
import { GET_PROFILE_DATA_EDIT } from '@/store/company/actions'
import { CompanyProfile } from '@/store/company/types'
import ProfilePicture from '@/components/common/ProfilePicture.vue'

@Component({
  components: { ProfilePicture, TargetCompetenceProfileResult, SearchResult, ResultsPage, SelectedCompetences, DetailPage, LoadingSpinner }
})
export default class CompanyProfilePublic extends Vue {
  @Action(GET_PROFILE_DATA_EDIT)
  public getProfile: () => Promise<CompanyProfile>

  companyId: string | undefined = undefined
  isLoading = false

  errorMessage = ''
  showErrorMessage = false

  company: CompanyProfile = {}

  mounted (): void {
    this.companyId = this.$route.params.id
    this.loadData()
  }

  loadData (): void {
    if (this.companyId === undefined) {
      this.isLoading = true
      this.getProfile().then(data => {
        this.company = data
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
