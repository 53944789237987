















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { LegendType } from '@/store/types'

@Component({
  components: {}
})
/*
Displays a color based legend. Currently used for the KODE values.
@group OTHER
 */
export default class ColorLegend extends Vue {
  // The entries for the legend are set in an array containing objects of the LegendType. It contains color and string.
  @Prop({ required: true })
  entries: LegendType[]
}
