var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"target-competence-profile-result",attrs:{"id":'target-competences-result-' + _vm.id}},[_c('div',{staticClass:"home"},[(!_vm.showDesktopVersion)?_c('color-legend',{staticClass:"color-legend pt-2",attrs:{"entries":_vm.legend}}):_vm._e(),_c('b-table',{attrs:{"stacked":!_vm.showDesktopVersion,"sort-icon-left":"","striped":false,"hover":false,"responsive":!_vm.showDesktopVersion,"items":_vm.competenceDetails,"fields":_vm.showRating ? [{ key: 'competence', label: '' }, { key: 'chart', label: '' }, { key: 'rating', label: '' }] : [{ key: 'competence', label: '' }, { key: 'chart', label: '' }]},scopedSlots:_vm._u([{key:"head(chart)",fn:function(){return [_c('color-legend',{attrs:{"entries":_vm.legend}})]},proxy:true},{key:"cell(competence)",fn:function(data){return [_c('competence',{attrs:{"index":_vm.id + '_' + data.index,"is-id":true,"competence":data.item.competenceId}})]}},{key:"cell(chart)",fn:function(data){return [_c('competence-corridor',{key:'corridor-' + data.index,style:({ maxHeight: '50px' }),attrs:{"scale-min":0,"scale-max":100,"profile-corridor-min":data.item.corridorMin100,"profile-corridor-max":data.item.corridorMax100,"student-results":{
            favMin: data.item.kodeFavorableMin100,
            favMax: data.item.kodeFavorableMax100,
            unfavMin: data.item.kodeUnfavorableMin100,
            unfavMax: data.item.kodeUnfavorableMax100,
            avgMin: data.item.kodeAverageMin100,
            avgMax: data.item.kodeAverageMax100
          }}})]}},(_vm.showRating)?{key:"cell(rating)",fn:function(data){return [_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",modifiers:{"hover":true,"topleft":true}}],staticClass:"material-icons",attrs:{"title":_vm.$i18n.t(_vm.getIcon(data.item) === 'mood' ? 'job-finder.good-competence' : _vm.$i18n.t(_vm.getIcon(data.item) === 'feedback' ? 'job-finder.overfulfilled' : 'job-finder.needs-work'))}},[_vm._v(" "+_vm._s(_vm.getIcon(data.item))+" ")])]}}:null],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }