













































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { State } from 'vuex-class'

@Component({
  components: {}
})
// This is a base frame for a detail page, used for talent profile, company profile and display of job ad.
// Fill with the props and slots described below.
// It features a header element which uses the full width for title, subtitle, header body, header buttons and image.
// The body of the detail page can be used alone in full width or at a distribution of 8:4 with an optional side element.
// @group SEARCH RESULTS
export default class DetailPage extends Vue {
  @State('currentScreenWidth') screenWidth: number

  //  h1 title in the header element
  @Prop()
  title: string

  // subtitle, located under the title
  @Prop()
  subTitle: string

  get headerColumnCols (): number {
    if (this.hasImage && this.screenWidth >= bootstrapBreakPoints.md) {
      return 9
    } else return 12
  }

  get hasImage () {
    return !!this.$slots.image
  }

  get hasSideElement () {
    return !!this.$slots.sideElement
  }
}
