










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { LOAD_IMAGE } from '@/store/user/actions.type'
import { FileDto, FileRequest } from '@/store/types'

@Component({})
/*
To display profile pictures.
 */
export default class ProfilePicture extends Vue {
  // role of the user (MENTOR, COMPANY, TALENT, ..)
  @Prop({ required: true })
  role: string

  // unique identifier
  @Prop({ required: true })
  id: string

  // the maximum width of the picture
  @Prop({ default: '100px' })
  maxWidth: string

  // the maximum height of the picture
  @Prop({ default: '100px' })
  maxHeight: string

  @Action(LOAD_IMAGE)
  public loadImage: (request: FileRequest) => Promise<FileDto>

  image: FileDto = { base64Content: '', filename: '' }

  mounted (): void {
    this.loadImage({ role: this.role, id: this.id }).then((data: FileDto) => {
      this.image = data
    })
  }
}
